import { configureStore } from '@reduxjs/toolkit';
import postsReducer from './features/blog/postsSlice';
import categoryReducer from './features/blog/categorySlice';
import tagsReducer from './features/blog/tagsSlice';

export const store = configureStore({
  reducer: {
    posts: postsReducer,
    categories: categoryReducer,
    tags: tagsReducer,
  },
});
