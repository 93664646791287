import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import Appeal from '../../Components/Appeal/Appeal';
import Techtools from '../../Components/TechTools/Techtools';

const Service = () => {
  const [devSite, setDevSite] = useState([]);
  const [designSite, setDesignSite] = useState([]);
  const [marketing, setMarketing] = useState([]);
  const [creativ, setCreativ] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setDevSite(t('service.listItems1', { returnObjects: true }));
    setDesignSite(t('service.listItems2', { returnObjects: true }));
    setMarketing(t('service.listItems3', { returnObjects: true }));
    setCreativ(t('service.listItems4', { returnObjects: true }));
  }, [t]);

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 - {`${t('navService')}`}</title>
      </Helmet>

      <motion.div
        className="page-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <div className="service">
          <div className="service_container container">
            <div className="service_wrap">
              <div className="service_head">
                <div className="service_head_row row align-items-center">
                  <div className="service_head_col col-xl-6 col-lg-6">
                    <h1 className="service_maintitle">{t('service.title')}</h1>
                  </div>
                  <div className="service_head_col col-xl-6 col-lg-6">
                    <p className="service_maindesc">{t('service.desc')}</p>
                  </div>
                </div>
              </div>

              <div className="service_lists">
                <div className="service_lists_row row">
                  <div className="service_lists_col col-xl-6 col-lg-6 col-md-6">
                    <div className="servicelist">
                      <div className="servicelist_iconwrap">
                        <svg
                          className="servicelist_icon"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 512 512"
                          // style="enableBackground: new 0 0 512 512"
                          // xml:space="preserve"
                        >
                          <g transform="translate(1 1)">
                            <g>
                              <g>
                                <path
                                  d="M58.733,24.6C39.96,24.6,24.6,39.96,24.6,58.733s15.36,34.133,34.133,34.133s34.133-15.36,34.133-34.133
                                S77.507,24.6,58.733,24.6z M58.733,75.8c-9.387,0-17.067-7.68-17.067-17.067s7.68-17.067,17.067-17.067S75.8,49.347,75.8,58.733
                                S68.12,75.8,58.733,75.8z"
                                />
                                <path
                                  d="M135.533,24.6c-18.773,0-34.133,15.36-34.133,34.133s15.36,34.133,34.133,34.133c18.773,0,34.133-15.36,34.133-34.133
                                S154.307,24.6,135.533,24.6z M135.533,75.8c-9.387,0-17.067-7.68-17.067-17.067s7.68-17.067,17.067-17.067
                                s17.067,7.68,17.067,17.067S144.92,75.8,135.533,75.8z"
                                />
                                <path
                                  d="M212.333,24.6c-18.773,0-34.133,15.36-34.133,34.133s15.36,34.133,34.133,34.133c18.773,0,34.133-15.36,34.133-34.133
                                S231.107,24.6,212.333,24.6z M212.333,75.8c-9.387,0-17.067-7.68-17.067-17.067s7.68-17.067,17.067-17.067
                                c9.387,0,17.067,7.68,17.067,17.067S221.72,75.8,212.333,75.8z"
                                />
                                <path
                                  d="M487.107-1H23.747C10.093-1-1,10.093-1,23.747V101.4v17.067V357.4v126.293v3.413C-1,500.76,9.24,511,22.893,511h3.413
                                h220.16h17.067h220.16h3.413C500.76,511,511,500.76,511,487.107v-3.413V357.4V118.467V101.4V23.747
                                C511,10.093,499.907-1,487.107-1z M26.307,493.933c-5.973,0-10.24-4.267-10.24-10.24V374.467h230.4v119.467H26.307z
                                M263.533,493.933V374.467h230.4v109.227c0,5.973-4.267,10.24-10.24,10.24H263.533z M493.933,357.4h-230.4h-17.067h-230.4
                                V118.467h477.867V357.4z M16.067,101.4V23.747c0-4.267,3.413-7.68,7.68-7.68h462.507c4.267,0,7.68,3.413,7.68,7.68V101.4H16.067z
                                "
                                />
                                <path
                                  d="M132.973,402.627c-3.413-3.413-8.533-3.413-11.947,0L78.36,445.293c-3.413,3.413-3.413,8.533,0,11.947
                                c1.707,1.707,3.413,2.56,5.973,2.56s4.267-0.853,5.973-2.56L127,420.547l36.693,36.693c1.707,1.707,3.413,2.56,5.973,2.56
                                s4.267-0.853,5.973-2.56c3.413-3.413,3.413-8.533,0-11.947L132.973,402.627z"
                                />
                                <path
                                  d="M419.693,402.627L383,439.32l-36.693-36.693c-3.413-3.413-8.533-3.413-11.947,0c-3.413,3.413-3.413,8.533,0,11.947
                                l42.667,42.667c1.707,1.707,3.413,2.56,5.973,2.56s4.267-0.853,5.973-2.56l42.667-42.667c3.413-3.413,3.413-8.533,0-11.947
                                C428.227,399.213,423.107,399.213,419.693,402.627z"
                                />
                                <path
                                  d="M84.333,161.133H127c5.12,0,8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533H84.333
                                c-5.12,0-8.533,3.413-8.533,8.533C75.8,157.72,79.213,161.133,84.333,161.133z"
                                />
                                <path
                                  d="M41.667,161.133H50.2c5.12,0,8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533h-8.533
                                c-5.12,0-8.533,3.413-8.533,8.533C33.133,157.72,36.547,161.133,41.667,161.133z"
                                />
                                <path
                                  d="M41.667,195.267H75.8c5.12,0,8.533-3.413,8.533-8.533S80.92,178.2,75.8,178.2H41.667c-5.12,0-8.533,3.413-8.533,8.533
                                S36.547,195.267,41.667,195.267z"
                                />
                                <path
                                  d="M127,178.2h-17.067c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533H127c5.12,0,8.533-3.413,8.533-8.533
                                S132.12,178.2,127,178.2z"
                                />
                                <path
                                  d="M417.133,289.133c0,5.12,3.413,8.533,8.533,8.533h42.667c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533
                                h-42.667C420.547,280.6,417.133,284.013,417.133,289.133z"
                                />
                                <path
                                  d="M383,297.667h8.533c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H383c-5.12,0-8.533,3.413-8.533,8.533
                                S377.88,297.667,383,297.667z"
                                />
                                <path
                                  d="M383,331.8h34.133c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H383c-5.12,0-8.533,3.413-8.533,8.533
                                S377.88,331.8,383,331.8z"
                                />
                                <path
                                  d="M468.333,314.733h-17.067c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533h17.067
                                c5.12,0,8.533-3.413,8.533-8.533S473.453,314.733,468.333,314.733z"
                                />
                                <path
                                  d="M193.56,331.8h122.88c8.533,0,15.36-6.827,16.213-14.507V203.8v-44.373c0-8.533-6.827-15.36-15.36-15.36H193.56
                                c-8.533,0-15.36,6.827-15.36,15.36V203.8v112.64C178.2,324.973,185.027,331.8,193.56,331.8z M195.267,161.133h119.467v25.6
                                H195.267V161.133z M314.733,203.8v110.933H195.267V203.8H314.733z"
                                />
                                <path
                                  d="M226.84,297.667h56.32c7.68,0,14.507-6.827,14.507-14.507v-22.187c0-7.68-6.827-14.507-14.507-14.507h-56.32
                                c-7.68,0-14.507,6.827-14.507,14.507v22.187C212.333,290.84,219.16,297.667,226.84,297.667z M229.4,263.533h51.2V280.6h-51.2
                                V263.533z"
                                />
                                <path
                                  d="M400.067,186.733h17.067V203.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533v-17.067h17.067
                                c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H434.2V152.6c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
                                v17.067h-17.067c-5.12,0-8.533,3.413-8.533,8.533S394.947,186.733,400.067,186.733z"
                                />
                                <path
                                  d="M115.907,266.093c-3.413-3.413-8.533-3.413-11.947,0L84.333,285.72l-19.627-19.627c-3.413-3.413-8.533-3.413-11.947,0
                                s-3.413,8.533,0,11.947l19.627,19.627L52.76,317.293c-3.413,3.413-3.413,8.533,0,11.947c1.707,1.707,3.413,2.56,5.973,2.56
                                s4.267-0.853,5.973-2.56l19.627-19.627l19.627,19.627c1.707,1.707,3.413,2.56,5.973,2.56s4.267-0.853,5.973-2.56
                                c3.413-3.413,3.413-8.533,0-11.947L96.28,297.667l19.627-19.627C119.32,274.627,119.32,269.507,115.907,266.093z"
                                />
                              </g>
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      </div>
                      <div className="servicelist_info">
                        <h3 className="servicelist_title">{t('service.listTitleWebSite')}</h3>
                        <ul className="servicelist_list">
                          {devSite.map((itm, i) => {
                            return (
                              <li className="servicelist_list_itm" key={i}>
                                {itm}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="service_lists_col col-xl-6 col-lg-6 col-md-6">
                    <div className="servicelist">
                      <div className="servicelist_iconwrap">
                        <svg
                          className="servicelist_icon"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 496 496"
                          // style={{ enableBackground: 'new 0 0 496 496;' }}
                          // xml:space="preserve"
                        >
                          <g>
                            <g>
                              <g>
                                <path d="M0,0v400h496V0H0z M480,384H16V16h464V384z" />
                                <path d="M312,32H32v176h280V32z M296,192H48V48h248V192z" />
                                <rect x="328" y="32" width="136" height="16" />
                                <rect x="328" y="64" width="136" height="16" />
                                <rect x="328" y="96" width="136" height="16" />
                                <rect x="328" y="128" width="136" height="16" />
                                <rect x="328" y="160" width="136" height="16" />
                                <rect x="328" y="192" width="136" height="16" />
                                <path d="M168,224H32v144h136V224z M152,352H48V240h104V352z" />
                                <path d="M184,368h128V224H184V368z M200,240h96v112h-96V240z" />
                                <path d="M464,224H328v144h136V224z M448,352H344V240h104V352z" />
                                <path
                                  d="M168,176c30.88,0,56-25.12,56-56s-25.12-56-56-56s-56,25.12-56,56S137.12,176,168,176z M168,80c22.056,0,40,17.944,40,40
				c0,22.056-17.944,40-40,40c-22.056,0-40-17.944-40-40C128,97.944,145.944,80,168,80z"
                                />
                                <path
                                  d="M456,416H77.872l-70,40l70,40H456c22.056,0,40-17.944,40-40C496,433.944,478.056,416,456,416z M80,478.784L40.128,456
				L80,433.216V478.784z M384,480H96v-48h288V480z M416,480h-16v-48h16V480z M456,480h-24v-48h24c13.232,0,24,10.768,24,24
				S469.232,480,456,480z"
                                />
                              </g>
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      </div>
                      <div className="servicelist_info">
                        <h3 className="servicelist_title">{t('service.listTitleDesign')}</h3>
                        <ul className="servicelist_list">
                          {designSite.map((itm, i) => {
                            return (
                              <li className="servicelist_list_itm" key={i}>
                                {itm}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="service_lists_col col-xl-6 col-lg-6 col-md-6">
                    <div className="servicelist">
                      <div className="servicelist_iconwrap">
                        <svg
                          className="servicelist_icon"
                          width="512px"
                          height="512px"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill="#000"
                            d="M23 23v466h466v-18H41v-82.184l85.854-57.234 70.023 70.022 65.133-260.536L387.28 203.7 455.07 95.73l19.317 11.858 6.102-71.1-60.644 37.616 19.884 12.207-59.01 93.99-130.732-65.366-62.865 251.462-57.98-57.978L41 367.184V23H23z"
                          />
                        </svg>
                      </div>
                      <div className="servicelist_info">
                        <h3 className="servicelist_title">{t('service.listTitleMarketing')}</h3>
                        <ul className="servicelist_list">
                          {marketing.map((itm, i) => {
                            return (
                              <li className="servicelist_list_itm" key={i}>
                                {itm}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="service_lists_col col-xl-6 col-lg-6 col-md-6">
                    <div className="servicelist">
                      <div className="servicelist_iconwrap">
                        <svg
                          className="servicelist_icon"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 60 60"
                          // style="enable-background:new 0 0 60 60;"
                          // xml:space="preserve"
                        >
                          <g>
                            <g>
                              <path
                                d="M57,0H3C1.3,0,0,1.3,0,3v37v9c0,0.6,0.4,1,1,1h17v-2H2v-7h16v-2H2V3c0-0.6,0.4-1,1-1h54c0.6,0,1,0.4,1,1v36H43v2h15v7H43
			v2h16c0.6,0,1-0.4,1-1v-9V3C60,1.3,58.7,0,57,0z"
                              />
                              <path
                                d="M46.3,26H51v-2h-4.8C45.7,15.9,39.1,9.4,31,9V5h-2v4.1c-7.9,0.6-14.2,7-14.6,14.9H8v2h6.3c0.4,7.2,5.6,13.2,12.7,14.6V46
			h-2c-0.6,0-1,0.4-1,1v13h2v-3h5v-2h-5v-3h5v-2h-5v-2h2h5h2v12h2V47c0-0.6-0.4-1-1-1h-2v-5.4C40.9,39,45.8,33.1,46.3,26z M44.2,24
			h-2c-0.5-5.9-5.3-10.6-11.2-11v-2C38,11.4,43.7,17,44.2,24z M29,24h-8.7c0.5-4.6,4.1-8.3,8.7-8.9V24z M24,26v3h2v-3h3v7h2v-7h4v3
			h2v-3h3.2c-0.5,5-4.8,9-9.9,9s-9.4-4-9.9-9H24z M31,24v-9c4.8,0.3,8.8,4.2,9.2,9H31z M29,11.1v2c-5.8,0.6-10.2,5.2-10.7,10.9h-2
			C16.8,17.2,22.2,11.7,29,11.1z M29,46v-6.2c0-0.5-0.3-0.9-0.8-1c-6.6-1-11.4-6.3-11.9-12.8h2c0.5,6.1,5.7,11,11.9,11
			c6.3,0,11.4-4.9,11.9-11h2c-0.4,6.4-5.1,11.7-11.5,12.8c-0.5,0.1-0.8,0.5-0.8,1V46H29z"
                              />
                              <rect x="43" y="5" width="4" height="2" />
                              <rect x="43" y="9" width="4" height="2" />
                              <rect x="50" y="5" width="4" height="2" />
                              <rect x="50" y="9" width="4" height="2" />
                              <rect x="50" y="13" width="4" height="2" />
                              <rect x="50" y="17" width="4" height="2" />
                              <rect x="13" y="5" width="4" height="2" />
                              <rect x="13" y="9" width="4" height="2" />
                              <rect x="6" y="5" width="4" height="2" />
                              <rect x="6" y="9" width="4" height="2" />
                              <rect x="6" y="13" width="4" height="2" />
                              <rect x="6" y="17" width="4" height="2" />
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      </div>
                      <div className="servicelist_info">
                        <h3 className="servicelist_title">{t('service.listTitleCreate')}</h3>
                        <ul className="servicelist_list">
                          {creativ.map((itm, i) => {
                            return (
                              <li className="servicelist_list_itm" key={i}>
                                {itm}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Techtools />
        <Appeal />
      </motion.div>
    </>
  );
};

export default Service;
