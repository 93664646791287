import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import PostCard from '../../Components/PostCard/PostCard';

import { capitalLetterStart } from '../../helper/capitalLetterStart';

import { getPosts, getPostsLast, getPostsCategory } from '../../features/blog/postsSlice';
import { getCategory } from '../../features/blog/categorySlice';
import { getTags } from '../../features/blog/tagsSlice';

import PostLoader from '../../Components/Skeleton/PostLoader';

const CATEGORY_DEFAULT = 'all';

const Blog = () => {
  const dispatch = useDispatch();
  const { postsAll, postsLast, loadingPostsLast } = useSelector((state) => state.posts);
  const { category } = useSelector((state) => state.categories);
  const { tags } = useSelector((state) => state.tags);

  const [catActive, setCatActive] = useState(CATEGORY_DEFAULT);

  const { t } = useTranslation();

  const handleClickGetCategory = (id) => {
    dispatch(getPostsCategory(id));
  };

  const handleClickGetAllPosts = () => {
    dispatch(getPosts());
  };

  useEffect(() => {
    dispatch(getPosts());
    dispatch(getPostsLast());
    dispatch(getCategory());
    dispatch(getTags());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 - {`${t('navBlog')}`}</title>
      </Helmet>

      <motion.div className="page-wrapper" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="blog">
          <div className="blog_container container">
            <div className="blog_wrap">
              <div className="blog_headmainrow row">
                <div className="blog_headmaincol col-12">
                  <h1 className="blog_maintitle">
                    <Trans i18nKey="blog.title"></Trans>
                  </h1>
                </div>
              </div>
            </div>

            <div className="blog_wrap">
              <div className="blog_lastpostrow row">
                {loadingPostsLast
                  ? [...new Array(2)].map((_, index) => {
                      return (
                        <div className="blog_lastpostcol col-xl-6 col-lg-6 col-md-6" key={index}>
                          <PostLoader />
                        </div>
                      );
                    })
                  : postsLast.map((itm) => {
                      return (
                        <div className="blog_lastpostcol col-xl-6 col-lg-6 col-md-6" key={itm.id}>
                          <PostCard howcard="big" tagsName={tags} {...itm} />
                        </div>
                      );
                    })}
              </div>

              <div className="blog_actionsrow row">
                <div className="blog_actionscol col-xl-6">
                  <div className="blogcategory">
                    {category.slice(0, -1).map((itm) => {
                      if (itm.id === 'all') {
                        return (
                          <button
                            key={itm.id}
                            className={`blogcategory_btn ${catActive === itm.id ? 'active' : ''}`}
                            onClick={() => {
                              setCatActive(itm.id);
                              handleClickGetAllPosts();
                            }}>
                            {capitalLetterStart(itm.name)}
                          </button>
                        );
                      } else {
                        return (
                          <button
                            className={`blogcategory_btn ${catActive === itm.id ? 'active' : ''}`}
                            key={itm.id}
                            onClick={() => {
                              setCatActive(itm.id);
                              handleClickGetCategory(itm.id);
                            }}>
                            {capitalLetterStart(itm.name)}
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>

              <div className="blog_allpostrow row">
                {postsAll.map((itm) => {
                  return (
                    <div className="blog_allpostcol col-xl-3 col-lg-3 col-md-4 col-sm-6" key={itm.id}>
                      <PostCard howcard="small" tagsName={tags} {...itm} />
                    </div>
                  );
                })}
              </div>
            </div>

            {/* <div className="blog_headmainrow row">
              <div className="blog_headmaincol col-12">
                <p className="infodev">{t('infodev')}</p>
              </div>
            </div> */}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Blog;
