import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 - {`${t('nav404')}`}</title>
      </Helmet>

      <div className="page-wrapper">
        <div className="notfound">
          <div className="container">
            <div className="notfound_content">
              <h2 className="notfound_suptitle">
                <Trans i18nKey="404page.title"></Trans>
              </h2>
              <p className="notfound_desc">
                {t('404page.text1')}
                <Link className="notfound_linkhome" to="/">
                  {t('404page.link')}
                </Link>
                {t('404page.text2')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
