import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';

const StandWithUkraine = ({ title, linkTo, onRemove }) => {
  const onClickDelete = () => {
    onRemove();
  };

  return (
    <div className="ukrwidgetline">
      <button className="ukrwidgetline_close" onClick={onClickDelete}>
        <AiOutlineClose />
      </button>
      <a className="ukrwidgetline_content" href={linkTo} target="_blank" rel="noreferrer">
        <h4 className="ukrwidgetline_title">{title}</h4>
      </a>
    </div>
  );
};

StandWithUkraine.propTypes = {
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

export default StandWithUkraine;
