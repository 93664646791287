import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CountUp from 'react-countup';

import Slider from '../../Components/Slider/Slider';
import Appeal from '../../Components/Appeal/Appeal';

const About = () => {
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setData(t('review', { returnObjects: true }));
  }, [t]);

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 - {`${t('navAbout')}`}</title>
      </Helmet>
      <motion.div
        className="page-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <div className="about">
          <div className="about_container container">
            <div className="about_wrap">
              <div className="about_rowhead row align-items-center">
                <div className="about_rowhead_col col-xl-6">
                  <h1 className="about_headtitle">
                    <Trans i18nKey="aboutTitle"></Trans>
                  </h1>
                </div>
                <div className="about_rowhead_col col-xl-6">
                  <p className="about_headdesc">{t('aboutDesc')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="whywe">
          <div className="container">
            <div className="whywe_row row">
              <div className="whywe_col col-xl-4 col-lg-4 col-md-4">
                <div className="whyweitm">
                  <div className="whyweitm_count">
                    <CountUp end={5} duration={2} />+
                  </div>
                  <div className="whyweitm_info">
                    <p className="whyweitm_desc">{t('whyweCount1')}</p>
                  </div>
                </div>
              </div>
              <div className="whywe_col col-xl-4 col-lg-4 col-md-4">
                <div className="whyweitm">
                  <div className="whyweitm_count">
                    <CountUp end={99} duration={3} />+
                  </div>
                  <div className="whyweitm_info">
                    <p className="whyweitm_desc">{t('whyweCount2')}</p>
                  </div>
                </div>
              </div>
              <div className="whywe_col col-xl-4 col-lg-4 col-md-4">
                <div className="whyweitm">
                  <div className="whyweitm_count">
                    <CountUp end={11} duration={2} />+
                  </div>
                  <div className="whyweitm_info">
                    <p className="whyweitm_desc">{t('whyweCount3')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sliderreview">
          <div className="container">
            <div className="sliderreview_wrap">
              <h2 className="about_titlereview">{t('reviewTitle')}</h2>
              <Slider dataReview={data} />
            </div>
          </div>
        </div>

        <Appeal />
      </motion.div>
    </>
  );
};

export default About;
