import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './Page/home/Home';
import Portfolio from './Page/portfolio/Portfolio';
import About from './Page/about/About';
import Service from './Page/service/Service';
import PortfolioArticle from './Page/portfolioArticle/PortfolioArticle';
import Callback from './Page/callback/Callback';
import Blog from './Page/blog/Blog';
import Article from './Page/blog/Article';
import Privacy from './Page/privacy/Privacy';
import NotFound from './Page/notfound/NotFound';

import ScrollToTop from './helper/pageToTop';

export const PATH_ROUTE = {
  home: '/',
  about: '/about',
  service: '/service',
  portfolio: '/portfolio',
  portfolioId: '/portfolio/:id',
  callback: '/callback',
  blog: '/blog',
  blogId: '/blog/:id',
  privacy: '/privacy',
};

const Router = () => {
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path={PATH_ROUTE.home} index element={<Home />} />
          <Route path={PATH_ROUTE.about} element={<About />} />
          <Route path={PATH_ROUTE.service} element={<Service />} />
          <Route path={PATH_ROUTE.portfolio} element={<Portfolio />} />
          <Route path={PATH_ROUTE.portfolioId} element={<PortfolioArticle />} />
          <Route path={PATH_ROUTE.callback} element={<Callback />} />
          <Route path={PATH_ROUTE.blog} element={<Blog />} />
          <Route path={PATH_ROUTE.blogId} element={<Article />} />
          <Route path={PATH_ROUTE.privacy} element={<Privacy />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </>
  );
};

export default Router;
