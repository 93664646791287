import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import PortfolioItem from '../../Components/PortfolioItem/PortfolioItem';

const Portfolio = () => {
  const [data, setData] = useState([]);
  const [category, setCategory] = useState('all');

  const { t } = useTranslation();

  const CATEGORY_BUTTON = [
    { name: t('filterAll'), category: 'all' },
    { name: t('filterLanding'), category: 'land' },
    { name: t('filterCorporate'), category: 'corp' },
    { name: t('filterBusinesscard'), category: 'businesscard' },
    { name: t('filterSinglescreen'), category: 'singlescreen' },
  ];

  useEffect(() => {
    setData(t('portfolio', { returnObjects: true }));
  }, [t]);

  const handleFilter = (isFilter) => {
    setCategory(isFilter);
  };

  const filter = (array, filter) => {
    switch (filter) {
      case 'all':
        return array;
      case 'corp':
        return array.filter((item) => item.category === category);
      case 'land':
        return array.filter((item) => item.category === category);
      case 'businesscard':
        return array.filter((item) => item.category === category);
      case 'singlescreen':
        return array.filter((item) => item.category === category);

      default:
        return array;
    }
  };

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 - {`${t('navPortfolio')}`}</title>
      </Helmet>

      <motion.div className="page-wrapper" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="portfolio">
          <div className="container-full">
            <div className="filter portfolio_filter">
              {CATEGORY_BUTTON.map((item) => {
                return (
                  <button
                    className={`filter_btn portfolio_filter_btn ${category === item.category ? 'is-active' : ''}`}
                    key={item.name}
                    onClick={() => handleFilter(item.category)}>
                    {item.name}
                  </button>
                );
              })}
            </div>
            <div className="portfolio_wrapper">
              {data.length === 0 ? (
                <div className="portfolio_empty">
                  <p>{t('noWorks')}</p>
                </div>
              ) : (
                <PortfolioItem portfolioData={filter(data, category)} />
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Portfolio;
