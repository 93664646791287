export const animateIt = function(e) {
  const { offsetX: x, offsetY: y } = e.nativeEvent;
  const { offsetWidth: width, offsetHeight: height } = e.target;
  const move = 10;
  const xMove = x / width * (move * 2) - move;
  const YMove = y / height * (move * 2) - move;

  e.target.style.transform = `translate(${xMove}px, ${YMove}px)`;
  e.target.style.willChange  = `transform`;

  if(e.type === 'mouseleave') {
    e.target.style.transform = '';
    e.target.style.willChange  = `auto`;
  }
}