import { useState, useEffect } from "react";

const usePageStatusBar = () => {
  const [scroll, setScroll] = useState(0);

  const handleScroll = (e) => {
    const percent =
      (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
      100;

    setScroll(Math.round(percent));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scroll;
};

export default usePageStatusBar;
