import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
const Privacy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 - {`${t('navPrivacy')}`}</title>
      </Helmet>
      <motion.div
        className="page-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <div className="privacy">
          <div className="privacy_container container">
            <div className="privacy_wrap">
              <h1 className="privacy_title">{t('privacy.mainTitle')}</h1>
              <div className="privacy_rowhead row">
                <div className="privacy_rowhead_col col-12">
                  <div className="privacywrapper">
                    <h3 className="privacywrapper_title">{t('privacy.title1')}</h3>

                    <p className="privacywrapper_desc">{t('privacy.desc1')}</p>

                    <h3 className="privacywrapper_title">{t('privacy.title2')}</h3>
                    <p className="privacywrapper_desc">{t('privacy.desc2')}</p>

                    <h3 className="privacywrapper_title">{t('privacy.title3')}</h3>
                    <p className="privacywrapper_desc">{t('privacy.desc3')}</p>

                    <h3 className="privacywrapper_title">{t('privacy.title4')}</h3>
                    <p className="privacywrapper_desc">{t('privacy.desc4')}</p>

                    <h3 className="privacywrapper_title">{t('privacy.title5')}</h3>
                    <p className="privacywrapper_desc">{t('privacy.desc5')}</p>

                    <h3 className="privacywrapper_title">{t('privacy.title6')}</h3>
                    <p className="privacywrapper_desc">{t('privacy.desc6')}</p>

                    <h3 className="privacywrapper_title">{t('privacy.title7')}</h3>
                    <p className="privacywrapper_desc">{t('privacy.desc7')}</p>

                    <h3 className="privacywrapper_title">{t('privacy.title8')}</h3>
                    <p className="privacywrapper_desc">{t('privacy.desc8')}</p>

                    <h3 className="privacywrapper_title">{t('privacy.title9')}</h3>
                    <p className="privacywrapper_desc">{t('privacy.desc9')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Privacy;
