import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  postsAll: [],
  postsLast: [],
  postId: {},
  loadingPostsLast: true,
  loading: true,
  error: '',
};

export const getPosts = createAsyncThunk('posts/getPosts', async (_, { rejectWithValue }) => {
  try {
    const res = await axios.get('https://blog.starcoding.top/wp-json/wp/v2/posts/');
    return res.data;
  } catch (err) {
    return rejectWithValue('Opps there seems to be an error');
  }
});

export const getPostId = createAsyncThunk('posts/getPostId', async (id, { rejectWithValue }) => {
  try {
    // const res = await axios.get(`https://blog.starcoding.top/wp-json/wp/v2/posts/${id}`);
    const res = await axios.get(`https://blog.starcoding.top/wp-json/wp/v2/posts?slug=${id}`);

    return res.data[0];
  } catch (err) {
    return rejectWithValue('Opps there seems to be an error');
  }
});

export const getPostsLast = createAsyncThunk('posts/getPostsLast', async (_, { rejectWithValue }) => {
  try {
    const res = await axios.get('https://blog.starcoding.top/wp-json/wp/v2/posts?per_page=2');
    return res.data;
  } catch (err) {
    return rejectWithValue('Opps there seems to be an error');
  }
});

export const getPostsCategory = createAsyncThunk('posts/getPostsCategory', async (id, { rejectWithValue }) => {
  try {
    const res = await axios.get(`https://blog.starcoding.top/wp-json/wp/v2/posts?categories=${id}`);
    return res.data;
  } catch (err) {
    return rejectWithValue('Opps there seems to be an error');
  }
});

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    clearPostId: (state) => {
      state.postId = {};
    },
  },

  extraReducers: {
    [getPosts.pending]: (state) => {
      state.loading = true;
    },
    [getPosts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postsAll = payload;
    },
    [getPosts.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    [getPostsLast.pending]: (state) => {
      state.loadingPostsLast = true;
    },
    [getPostsLast.fulfilled]: (state, { payload }) => {
      state.loadingPostsLast = false;
      state.postsLast = payload;
    },
    [getPostsLast.rejected]: (state, { error }) => {
      state.loadingPostsLast = false;
      state.error = error;
    },

    [getPostId.pending]: (state) => {
      state.loading = true;
    },
    [getPostId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postId = payload;
    },
    [getPostId.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    [getPostsCategory.pending]: (state) => {
      state.loading = true;
    },
    [getPostsCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postsAll = payload;
    },
    [getPostsCategory.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
  },
});

export const { clearPostId } = postsSlice.actions;

export default postsSlice.reducer;
