import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Social from '../Social/Social';

const mediaQuery = 'screen and (min-width: 992px)';

const Nav = ({ handleClickBtnMenu, isOpen }) => {
  const mql = window.matchMedia(mediaQuery); // true or false
  const [showDesktopMenu, setShowDesktopMenu] = useState(mql.matches);

  const { t } = useTranslation();

  useEffect(() => {
    const handleMediaChange = function () {
      setShowDesktopMenu(this.matches);
    };
    mql.addEventListener('change', handleMediaChange);
    setShowDesktopMenu(mql.matches);

    return () => {
      mql.removeEventListener('change', handleMediaChange);
    };
  }, [mql]);

  if (showDesktopMenu) {
    return (
      <>
        <nav className="nav">
          <div className="nav_box nav_box_pc">
            <NavLink className="nav_link" to="/">
              {t('navHome')}
            </NavLink>
            <NavLink className="nav_link" to="/about">
              {t('navAbout')}
            </NavLink>
            <NavLink className="nav_link" to="/service">
              {t('navService')}
            </NavLink>
            <NavLink className="nav_link" to="/portfolio">
              {t('navPortfolio')}
            </NavLink>
            <NavLink className="nav_link nav_link-blog" to="/blog">
              {t('navBlog')}
            </NavLink>
            <NavLink className="nav_link nav_link-callback" to="/callback">
              {t('navSendUs')}
            </NavLink>
          </div>
        </nav>
      </>
    );
  }

  return (
    <>
      <nav className="nav">
        <button
          className={`nav_btnburger ${isOpen ? 'is-active' : ''}`}
          onClick={() => {
            handleClickBtnMenu();
            document.querySelector('body').classList.toggle('scroll-fix');
          }}>
          <span>{isOpen ? t('menuClose') : t('menuOpen')}</span>
        </button>
        <div className={`nav_blmob nav_box_mob ${isOpen ? 'is-active' : ''}`}>
          <div className="nav_blmob_content">
            <div className="nav_blmob_links">
              <NavLink
                className="nav_link"
                to="/"
                onClick={() => {
                  handleClickBtnMenu();
                  document.querySelector('body').classList.toggle('scroll-fix');
                }}>
                {t('navHome')}
              </NavLink>
              <NavLink
                className="nav_link"
                to="/about"
                onClick={() => {
                  handleClickBtnMenu();
                  document.querySelector('body').classList.toggle('scroll-fix');
                }}>
                {t('navAbout')}
              </NavLink>
              <NavLink
                className="nav_link"
                to="/service"
                onClick={() => {
                  handleClickBtnMenu();
                  document.querySelector('body').classList.toggle('scroll-fix');
                }}>
                {t('navService')}
              </NavLink>
              <NavLink
                className="nav_link"
                to="/portfolio"
                onClick={() => {
                  handleClickBtnMenu();
                  document.querySelector('body').classList.toggle('scroll-fix');
                }}>
                {t('navPortfolio')}
              </NavLink>
              <NavLink
                className="nav_link"
                to="/blog"
                onClick={() => {
                  handleClickBtnMenu();
                  document.querySelector('body').classList.toggle('scroll-fix');
                }}>
                {t('navBlog')}
              </NavLink>
              <NavLink
                className="nav_link"
                to="/callback"
                onClick={() => {
                  handleClickBtnMenu();
                  document.querySelector('body').classList.toggle('scroll-fix');
                }}>
                {t('navSendUs')}
              </NavLink>
            </div>
            <div className="nav_blmob_bottom">
              <Social classOther="nav_blmob_social" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
