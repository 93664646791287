import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import Messenger from '../../Components/Social/Messenger.jsx';

import { animateIt } from '../../helper/mousePositionAnimate';

const Appeal = () => {
  const { t } = useTranslation();

  const handleMouseMove = (e) => animateIt(e);

  return (
    <div className="appeal">
      <div className="container portarticle_container">
        <div className="appeal_wrap">
          <div className="appeal_wrap_row row align-items-center">
            <div className="appeal_wrap_col col-xl-7 col-lg-7 col-md-7">
              <div className="appeal_blkleft">
                <h2 className="appeal_title">
                  <Trans i18nKey="appealTitle"></Trans>
                </h2>
              </div>
            </div>
            <div className="appeal_wrap_col col-xl-5 col-lg-5 col-md-5">
              <div className="appeal_blkright">
                <Link
                  to="/callback"
                  className="m-button hover-link appeal_link appeal_link_rout"
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseMove}>
                  <span>{t('btnSendUs')}</span>
                </Link>
                <p className="appeal_smtext">{t('orMessenger')}</p>
                <div className="appeal_social">
                  <Messenger />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appeal;
