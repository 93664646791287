import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  tags: [],
  loading: false,
  error: '',
};

const COUT_ITEMS = 100;

export const getTags = createAsyncThunk('tags/getTags', async (_, { rejectWithValue }) => {
  try {
    const res = await axios.get(`https://blog.starcoding.top/wp-json/wp/v2/tags?per_page=${COUT_ITEMS}`);
    return res.data;
  } catch (err) {
    return rejectWithValue('Opps there seems to be an error');
  }
});

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},

  extraReducers: {
    [getTags.pending]: (state) => {
      state.loading = true;
    },
    [getTags.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tags = payload;
    },
    [getTags.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
  },
});

export default tagsSlice.reducer;
