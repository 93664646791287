import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  category: [],
  loading: false,
  error: '',
};

export const getCategory = createAsyncThunk(
  'category/getCategory',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get('https://blog.starcoding.top/wp-json/wp/v2/categories');
      const addAllItm = { id: 'all', name: 'all' };
      return [addAllItm, ...res.data];
    } catch (err) {
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},

  extraReducers: {
    [getCategory.pending]: (state) => {
      state.loading = true;
    },
    [getCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.category = payload;
    },
    [getCategory.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
  },
});

export default categorySlice.reducer;
