import React from "react";

import usePageStatusBar from "../../hooks/usePageStatusBar";

const ScrollProgress = () => {
  const countStatus = usePageStatusBar();

  return <div className="scrollbar" style={{ width: `${countStatus}%` }}></div>;
};

export default ScrollProgress;
