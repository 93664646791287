import React, { useState, useEffect } from "react";
import { AiOutlineToTop } from "react-icons/ai";

const TopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 1000) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 1000) {
        setShowScroll(false);
      }
    };
    window.addEventListener("scroll", checkScrollTop);

    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScroll]);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <button
      className={`buttontop ${showScroll ? "is-active" : "no-active"}`}
      type="button"
      onClick={scrollTop}
    >
      <AiOutlineToTop />
    </button>
  );
};

export default TopButton;
