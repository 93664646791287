import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoLogoHtml5, IoLogoCss3, IoLogoJavascript, IoLogoWordpress } from 'react-icons/io';
import {
  SiReact,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiFigma,
  SiPhp,
  SiWoo,
  SiInvision,
  SiTrello,
} from 'react-icons/si';

const ICON_TECHTOOLS = [
  {
    icon: <IoLogoHtml5 />,
  },
  {
    icon: <IoLogoCss3 />,
  },
  {
    icon: <IoLogoJavascript />,
  },
  {
    icon: <SiReact />,
  },
  {
    icon: <SiPhp />,
  },
  {
    icon: <IoLogoWordpress />,
  },
  {
    icon: <SiWoo />,
  },
  {
    icon: <SiFigma />,
  },
  {
    icon: <SiAdobephotoshop />,
  },
  {
    icon: <SiAdobeillustrator />,
  },
  {
    icon: <SiInvision />,
  },
  {
    icon: <SiTrello />,
  },
];

const Techtools = () => {
  const { t } = useTranslation();

  return (
    <div className="techtools">
      <div className="techtools_container container">
        <div className="techtools_wrap">
          <div className="techtools_head">
            <div className="techtools_head_row row align-items-center">
              <div className="techtools_head_col col-12">
                <h2 className="techtools_maintitle">{t('techTools')}</h2>
              </div>
            </div>
          </div>
          <div className="techtools_list">
            <div className="row techtools_list_row">
              {ICON_TECHTOOLS.map((itm, i) => {
                return (
                  <div className="techtools_list_col col-xl-1 col-lg-1 col-md-1 col-sm-2" key={i}>
                    <div className="techtoolsitm">{itm.icon}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Techtools;
