import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

const CopyToClip = ({ copyText, classOther }) => {
  const [state, copyToClipboard] = useCopyToClipboard();

  const { t } = useTranslation();

  const handleClickCopy = () => {
    copyToClipboard(copyText);
  };

  return (
    <div className={`copytoclip ${classOther && classOther}`}>
      <button className="copytoclip_btn" onClick={handleClickCopy}>
        {state.value ? t('copyToClip.end') : t('copyToClip.start')}
      </button>
    </div>
  );
};

export default CopyToClip;
