import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import CookieConsent from 'react-cookie-consent';

import Router from '../router';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import PageSpinner from './Spinner/PageSpinner';
import StandWithUkraineLine from './widget/StandWithUkraineLine';

import useGaTracker from '../useGaTracker';

// Style //
import '../style/app.scss';

const App = () => {
  const [loading, setLoading] = useState(false);
  const [widget, setWidget] = useState(true);

  useGaTracker();

  const onRemoveWidget = () => {
    setWidget(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 1700);
    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return <PageSpinner />;
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className="app"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.2 }}>
        {widget && (
          <StandWithUkraineLine
            title="stand with ukraine"
            linkTo="https://savelife.in.ua/en/donate-en/#donate-army-card-monthly"
            onRemove={onRemoveWidget}
          />
        )}

        <div className="paperOverlay"></div>

        <CookieConsent
          // debug="true"
          expires={365}
          sameSite="none"
          location="bottom"
          buttonText="I understand"
          style={{ background: '#000' }}
          buttonStyle={{ color: '#fff', background: '#ff8300', fontSize: '13px' }}>
          We use cookies on this site to enhance your user experience.
        </CookieConsent>

        <Header />
        <div className="main">
          <Router />
        </div>
        <Footer />
      </motion.div>
    </AnimatePresence>
  );
};

export default App;
