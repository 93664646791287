import React from 'react';
import ContentLoader from 'react-content-loader';

const PostLoader = (props) => {
  return (
    <ContentLoader
      className="skeletonloader"
      speed={2}
      width={'100%'}
      height={525}
      viewBox="0 0 540 525"
      backgroundColor="#ffffff"
      foregroundColor="#e3e3e3"
      {...props}>
      <rect x="0" y="0" rx="0" ry="0" width="540" height="265" />
      <rect x="0" y="306" rx="0" ry="0" width="540" height="69" />
      <rect x="0" y="419" rx="0" ry="0" width="540" height="105" />
    </ContentLoader>
  );
};

export default PostLoader;
