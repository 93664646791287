import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Spinner from '../../Components/Spinner/Spinner';
import Messenger from '../../Components/Social/Messenger.jsx';

const API_KEY = '-nK2cvSvkhLpfxQB3';

const Callback = () => {
  const [sendStatus, setSendStatus] = useState(false);
  const [succesMessage, setSuccesMessage] = useState(false);

  const { t } = useTranslation();

  const phoneRegExp = /^[0-9]+$/;

  const schema = yup
    .object({
      name: yup.string().required(t('formValidate.filedRequired')).min(2, t('formValidate.moreCharName')),
      tel: yup.string().required(t('formValidate.filedRequired')).matches(phoneRegExp, t('formValidate.onlyNumb')),
      message: yup.string().required(t('formValidate.filedRequired')).min(10, t('formValidate.moreCharMessage')),
      checkPolicy: yup.boolean().oneOf([true]).required(t('formValidate.filedRequired')),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  let navigate = useNavigate();

  const handleFormSubmit = (_, e) => {
    setSendStatus(true);
    emailjs.sendForm('start-coding-email-14364', 'template_nh9pdsd', e.target, API_KEY).then(
      () => {
        setSendStatus(false);
        setSuccesMessage(true);
        reset();
        setTimeout(() => {
          navigate('/');
        }, 3000);
      },
      (error) => {
        console.log(error.text);
      },
    );
  };

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 - {`${t('navSendUs')}`}</title>
      </Helmet>

      <motion.div className="page-wrapper" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="callback">
          <div className="container callback_container">
            <div className="callback_content">
              <div className="callback_head">
                <h2 className="callback_title">{t('contTitle')}</h2>
                <div className="callback_social">
                  <span className="callback_social_text">{t('conttOr')}</span>
                  <Messenger otherCLass="calbmesseg" />
                </div>
                {succesMessage && <p className="callback_succesmessage">{t('successSend')}</p>}
              </div>

              <form
                className={`form ${sendStatus ? 'is-active' : ''}`}
                onSubmit={handleSubmit(handleFormSubmit)}
                autoComplete="off">
                <div className="form_wrap">
                  <div className="form_box">
                    <label className="form_label" htmlFor="form-name">
                      {t('contInputName')}
                    </label>
                    <input
                      className="form_input"
                      type="text"
                      id="form-name"
                      name="name"
                      placeholder={t('contInputNamePlahold')}
                      {...register('name')}
                    />
                    <span className="form_inpfocus"></span>
                    {errors.name?.message && <p className="form_error">{errors.name.message}</p>}
                  </div>
                  <div className="form_box">
                    <label className="form_label" htmlFor="form-tel">
                      {t('contInputTel')}
                    </label>
                    <input
                      className="form_input"
                      type="text"
                      name="tel"
                      id="form-tel"
                      placeholder={t('contInputTelPlahold')}
                      {...register('tel')}
                    />
                    <span className="form_inpfocus"></span>
                    {errors.tel?.message && <p className="form_error">{errors.tel.message}</p>}
                  </div>
                  <div className="form_box form_boxarea">
                    <label className="form_label" htmlFor="form-message">
                      {t('contInputMessage')}
                    </label>
                    <textarea
                      className="form_input form_textarea"
                      name="message"
                      id="form-message"
                      rows="5"
                      placeholder={t('contInputMessagePlahold')}
                      {...register('message')}></textarea>
                    <span className="form_inpfocus"></span>
                    {errors.message?.message && <p className="form_error">{errors.message.message}</p>}
                  </div>
                  <div className="form_othebox">
                    <p className="form_textmessage">{t('contRadioTitle')}</p>
                    <div className="form_grradio">
                      <label htmlFor="form-viber" className="form_radio">
                        <input type="radio" name="messanger" value="viber" id="form-viber" {...register('messanger')} />
                        <span>Viber</span>
                      </label>
                      <label htmlFor="form-telegram" className="form_radio">
                        <input
                          type="radio"
                          name="messanger"
                          value="telegram"
                          id="form-telegram"
                          {...register('messanger')}
                        />
                        <span>Telegram</span>
                      </label>
                      <label htmlFor="form-whatsapp" className="form_radio">
                        <input
                          type="radio"
                          name="messanger"
                          value="whatsapp"
                          id="form-whatsapp"
                          {...register('messanger')}
                        />
                        <span>Whatsapp</span>
                      </label>
                    </div>
                  </div>

                  <div className="form_bottom">
                    <label className="form_check" htmlFor="form-check">
                      <input type="checkbox" id="form-check" name="checkPolicy" {...register('checkPolicy')} />
                      <div className="form_check_block"></div>
                      <Link to="/privacy">{t('contCheckPolitica')}</Link>
                      {errors.checkPolicy && errors.checkPolicy.type === 'oneOf' && (
                        <p className="form_error">{t('formValidate.filedRequired')}</p>
                      )}
                    </label>
                    <button className="m-button form_send">
                      <span>{t('contSendBtn')}</span>
                    </button>
                  </div>
                </div>
              </form>
              {sendStatus && <Spinner />}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Callback;
