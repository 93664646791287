import React from "react";
import { motion } from "framer-motion";

const PageSpinner = () => {
  return (
    <div className="page-loader">
      <motion.div
        className="page-loader-left"
        initial={{ height: "0%" }}
        animate={{ height: ["100%", "0%"] }}
        exit={{ height: "0%" }}
        transition={{ ease: [0.46, 0.01, 0.27, 1], duration: 1.6 }}
      ></motion.div>
      <motion.div
        className="page-loader-right"
        initial={{ height: "0%" }}
        animate={{ height: ["100%", "0%"] }}
        exit={{ height: "0%" }}
        transition={{ ease: [0.46, 0.01, 0.27, 1], duration: 1.6 }}
      ></motion.div>
    </div>
  );
};

export default PageSpinner;
