import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { AiFillStar } from 'react-icons/ai';

import 'swiper/css';
import 'swiper/css/pagination';

const Slider = ({ dataReview }) => {
  return (
    <div className="slidertempwrapper">
      <Swiper
        className="slidertemp reviewslider"
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        {dataReview.map((itm) => {
          const { id, langorigin, desc, author, position } = itm;
          return (
            <SwiperSlide className="slidertemp_itm" key={id}>
              <div className="slidertemp_itm_star">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
              </div>
              <div className="slidertemp_itm_lang">{langorigin}</div>
              <div className="slidertemp_itm_desc">{desc}</div>
              <div className="slidertemp_itm_info">
                <div className="slidertemp_itm_author">{author}</div>
                <div className="slidertemp_itm_position">{position}</div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  dataReview: PropTypes.array.isRequired,
};

export default Slider;
