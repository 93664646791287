import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const PortfolioItem = ({ portfolioData }) => {
  const linkAction = (item) => {
    return item.case ? (
      <Link to={`/portfolio/${item.link}`} className="portblock_item_link">
        перейти на сайт
      </Link>
    ) : (
      <a
        href={`${item.linkToPage}`}
        target="_blank"
        rel="noopener noreferrer"
        className="portblock_item_link"
      >
        перейти на сайт
      </a>
    );
  };

  return (
    <>
      {portfolioData.map((item, i) => {
        return (
          <div className="portblock_wrapblock portblock" key={item.id}>
            <div className="portblock_item">
              {linkAction(item)}
              <motion.div
                className="portblock_item_content"
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -50, opacity: 0 }}
                transition={{ type: "spring" }}
              >
                <div className="portblock_item_count">
                  {i + 1 <= 9 ? `0${i + 1}` : `${i + 1}`}
                </div>
                <div className="portblock_item_info">
                  <h4 className="portblock_item_name">{item.title}</h4>
                  <div className="portblock_item_contbot">
                    <span className="portblock_item_type">
                      / {item.whatDid.join(" , ")}
                    </span>

                    {item.case && (
                      <div className="portblock_item_lablcase">
                        {item.withArticle}
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>

              <div className="portblock_preview">
                <img src={item.image} alt={item.title} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PortfolioItem;
