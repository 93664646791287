import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Helmet } from 'react-helmet-async';

import Social from '../../Components/Social/Social';
import ButtonTop from '../../Components/TopButton/TopButton';

import Appeal from '../../Components/Appeal/Appeal';
import ScrollProgress from '../../Components/ScrollProgress/ScrollProgress';

const PortfolioArticle = () => {
  const [data, setData] = useState({});
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const dataSingle = t('portfolioArticle', { returnObjects: true }).filter(
      (item) => item.id === params.id,
    );
    setData(dataSingle[0]);
  }, [t, params.id]);

  return (
    <>
      <Helmet>
        <title>
          StarCoding 🚀 - {`${t('wordcase')}`}, {`${data.mainTitle}`}
        </title>
      </Helmet>

      <motion.div
        className="page-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <ButtonTop />
        <ScrollProgress />
        <div className="portarticle">
          <div className="container portarticle_container">
            <div className="portarticle_content">
              <Social classOther="portarticle_social" />
              <div className="portarticle_head">
                <div className="portarticle_layout_col portarticle_layout_col-margin">
                  <div className="portarticle_layout_col_left">
                    <Link to="/portfolio/" className="link-route-back portarticle_linkback">
                      <AiOutlineArrowLeft />
                      {t('articleLinkGoBack')}
                    </Link>
                  </div>
                  <div className="portarticle_layout_col_right">
                    <div className="portarticle_toppannel">
                      <div className="portarticle_tag">
                        {t('articleType')} - {data.tag}
                      </div>
                      <a
                        className="portarticle_linktopage"
                        href={data.linkToPage}
                        target="_blank"
                        rel="noopener noreferrer">
                        {t('articleLinkPage')}
                      </a>
                    </div>
                    <div className="portarticle_topinfo">
                      <h1 className="portarticle_title">{data.mainTitle}</h1>
                      <p className="portarticle_subdesc">{data.mainDesc}</p>
                    </div>
                  </div>
                </div>
                <div className="portarticle_maintopimg">
                  <picture>
                    <source srcSet={data.mainImageMob} media="(max-width: 480px)" />
                    <img src={data.mainImage} alt="превью" />
                  </picture>
                </div>
              </div>
              <div className="portarticle_mid">
                <div className="portarticle_mid_row">
                  <div className="portarticle_layout_col portarticle_layout_col-margin">
                    <div className="portarticle_layout_col_left">
                      <h4 className="portarticle_othertitle">{t('articleTitleForWhom')}</h4>
                    </div>
                    <div className="portarticle_layout_col_right">
                      <p className="portarticle_otherdesc">{data.forWhom}</p>
                    </div>
                  </div>
                  <div className="portarticle_layout_col portarticle_layout_col-margin">
                    <div className="portarticle_layout_col_left">
                      <h4 className="portarticle_othertitle">{t('articleTitlePurpose')}</h4>
                    </div>
                    <div className="portarticle_layout_col_right">
                      <p className="portarticle_otherdesc">{data.whatPurpose}</p>
                    </div>
                  </div>
                </div>
                <h2 className="portarticle_othermaintitle">{t('articleTitleSolution')}</h2>
                <div className="portarticle_mid_row">
                  <div className="portarticle_proto_img">
                    <picture>
                      <source srcSet={data.protoImageMob} media="(max-width: 480px)" />
                      <img src={data.protoImage} alt="прототип сайта" />
                    </picture>
                  </div>
                  <div className="portarticle_layout_col portarticle_layout_col-margin">
                    <div className="portarticle_layout_col_left">
                      <h4 className="portarticle_othertitle">{t('articleTitleProto')}</h4>
                    </div>
                    <div className="portarticle_layout_col_right">
                      <p className="portarticle_otherdesc">{data.protoText}</p>
                    </div>
                  </div>
                </div>
                <div className="portarticle_mid_row">
                  <div className="portarticle_design_img">
                    <picture>
                      <source srcSet={data.designImageMob} media="(max-width: 480px)" />
                      <img src={data.designImage} alt="дизайн сайта" />
                    </picture>
                  </div>
                  <div className="portarticle_layout_col portarticle_layout_col-margin">
                    <div className="portarticle_layout_col_left">
                      <h4 className="portarticle_othertitle">{t('articleTitleDesign')}</h4>
                    </div>
                    <div className="portarticle_layout_col_right">
                      <p className="portarticle_otherdesc">{data.designText}</p>
                    </div>
                  </div>
                </div>
                <div className="portarticle_mid_row">
                  <div className="portarticle_layout_col portarticle_layout_col-margin">
                    <div className="portarticle_layout_col_left">
                      <h4 className="portarticle_othertitle">{t('articleTitleProgram')}</h4>
                    </div>
                    <div className="portarticle_layout_col_right">
                      <p className="portarticle_otherdesc">{data.codingText}</p>
                    </div>
                  </div>
                </div>
                <h2 className="portarticle_othermaintitle">{t('articleTitleResult')}</h2>
                <div className="portarticle_mid_row">
                  <div className="portarticle_layout_col portarticle_layout_col-margin">
                    <div className="portarticle_layout_col_left">
                      <h4 className="portarticle_othertitle">{t('articleTitleWhatDidYou')}</h4>
                    </div>
                    <div className="portarticle_layout_col_right">
                      <p className="portarticle_otherdesc">{data.whatGotText}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Appeal />
      </motion.div>
    </>
  );
};

export default PortfolioArticle;
