import React from 'react';
import { FaTelegramPlane, FaViber, FaWhatsapp } from 'react-icons/fa';

const Messenger = ({ otherCLass }) => {
  const classess = `messenger ${otherCLass && otherCLass}`;

  return (
    <div className={classess}>
      <ul className="messenger_list">
        <li className="messenger_item">
          <a
            href="https://telegram.me/che_constantin"
            target="_blank"
            rel="noreferrer"
            className="messenger_link"
            title="telegram">
            <FaTelegramPlane />
          </a>
        </li>
        <li className="messenger_item">
          <a href="viber://chat?number=+380978441120" className="messenger_link" title="viber">
            <FaViber />
          </a>
        </li>
        <li className="messenger_item">
          <a
            href="https://wa.me/380978441120"
            className="messenger_link"
            target="_blank"
            rel="noreferrer"
            title="whatsapp">
            <FaWhatsapp />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Messenger;
