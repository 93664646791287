import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';

import Nav from '../Nav/Nav';

const LANG_LIST = [
  { id: 3, abr: 'ua', fullName: 'Ukraine' },
  { id: 2, abr: 'en', fullName: 'English' },
  { id: 1, abr: 'ru', fullName: 'Rusian' },
];

const DEFAULT_LANG = 'en';

const Header = () => {
  const localLang = window.localStorage.getItem('i18nextLng');

  const [open, setOpen] = useState(false);

  const handleClickBtnMenu = () => setOpen(!open);

  const onChangeLang = (val) => i18n.changeLanguage(val);

  return (
    <header className="header">
      <div className="container-full">
        <div className="header_wrap">
          <Link to="/" className="logo header_logo" title="StarCoding Studio">
            <picture>
              <img src="/img/logo.svg" alt="Star.Coding" />
            </picture>
          </Link>
          <div className="header_pannel">
            <Nav handleClickBtnMenu={handleClickBtnMenu} isOpen={open} />
            <LanguageSwitch
              language={localLang ? localLang : DEFAULT_LANG}
              languageList={LANG_LIST}
              onChangeLang={onChangeLang}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
