import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const fallbackLng = ['en'];
export const availableLanguages = ['ua', 'en', 'ru'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    supportedLngs: ['ua', 'en', 'ru'],

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: '/assets/locals/{{lng}}/translation.json',
    },
  });

export default i18n;
