import React from 'react';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';

const PostCard = ({ howcard, title, excerpt, featured_media_src_url, tags, tagsName, id, slug }) => {
  return (
    <div className={`postcard ${howcard}`}>
      <Link className="postcard_link" to={`/blog/${slug}`}>
        link to article
      </Link>
      <div className="postcard_top">
        <div className="postcard_tags">
          {tags.map((itm, i) => {
            return (
              <a href="#!program" key={i}>
                {decode(tagsName.find((tag) => tag.id === itm)?.name)}
              </a>
            );
          })}
        </div>
        <div className="postcard_image">
          <img className="postcard_picture" src={`${featured_media_src_url}`} alt="alt" />
        </div>
      </div>
      <h3 className="postcard_title">{decode(title.rendered)}</h3>
      <p className="postcard_desc" dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
    </div>
  );
};

export default PostCard;
