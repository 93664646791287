import React, { useState, useRef } from 'react';

import useOnClickOutside from '../../hooks/useOnClickOutside';

const LanguageSwitch = ({ language, languageList, onChangeLang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(language);

  const refListLang = useRef();

  useOnClickOutside(refListLang, () => setIsOpen(false));

  const togglingSwitch = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    onChangeLang(value);
  };

  return (
    <div className="langswitch" ref={refListLang}>
      <div
        className={`langswitch_header ${isOpen ? 'is-open' : 'is-close'}`}
        onClick={togglingSwitch}>
        {selectedOption.toUpperCase()}
      </div>
      {isOpen && (
        <div className="langswitch_content">
          <ul className="langswitch_list">
            {languageList.map((itm) => {
              const isActive = selectedOption === itm.abr;

              return (
                <li
                  className={`langswitch_list_itm ${isActive ? 'is-active' : 'is-default'}`}
                  key={itm.id}
                  tabIndex={itm.id}
                  onClick={onOptionClicked(itm.abr)}>
                  {itm.abr.toUpperCase()}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitch;
