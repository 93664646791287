import React from 'react';
import ContentLoader from 'react-content-loader';

const PostLoader = (props) => {
  return (
    <ContentLoader
      className="skeletonloader"
      speed={2}
      width={'100%'}
      height={640}
      viewBox="0 0 1110 640"
      backgroundColor="#ffffff"
      foregroundColor="#e3e3e3"
      {...props}>
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="444" />
      <rect x="0" y="460" rx="0" ry="0" width="200px" height="40" />
      <rect x="0" y="520" rx="0" ry="0" width="100%" height="140" />
    </ContentLoader>
  );
};

export default PostLoader;
