import React from 'react';
import { SiTelegram } from 'react-icons/si';
import { IoLogoWhatsapp } from 'react-icons/io';
import { AiOutlineInstagram, AiOutlineMail, AiFillLinkedin } from 'react-icons/ai';

const Social = ({ classOther }) => {
  const classess = `social ${classOther && classOther}`;

  return (
    <div className={classess}>
      <ul className="social_list">
        <li className="social_item">
          <a className="social_link" href="mailto:mail@starcoding.top" title="mail">
            <AiOutlineMail />
          </a>
        </li>
        <li className="social_item">
          <a
            className="social_link"
            href="https://telegram.me/che_constantin"
            target="_blank"
            rel="noreferrer"
            title="telegram">
            <SiTelegram />
          </a>
        </li>
        <li className="social_item">
          <a
            className="social_link"
            href="https://wa.me/380978441120"
            target="_blank"
            rel="noreferrer"
            title="whatsapp">
            <IoLogoWhatsapp />
          </a>
        </li>
        <li className="social_item">
          <a
            className="social_link"
            href="https://www.instagram.com/starcoding.studio/"
            target="_blank"
            rel="noreferrer"
            title="instagram">
            <AiOutlineInstagram />
          </a>
        </li>
        <li className="social_item">
          <a
            className="social_link"
            href="https://www.linkedin.com/company/starcoding/"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn">
            <AiFillLinkedin />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
