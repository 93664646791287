import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { animateIt } from '../../helper/mousePositionAnimate';

import Social from '../../Components/Social/Social';

const Home = () => {
  const { t } = useTranslation();

  const isLargScreen = useMediaQuery({ query: '(min-width: 1200px)' });

  const handleMouseMove = (e) => animateIt(e);

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 | Web Development, Web Design, Creative, SEO, SMM</title>
      </Helmet>

      <motion.div
        className="page-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <div className="home">
          <div className="container-full">
            <div className="backline">
              <div className="backline_item">
                <div className="backline_animate"></div>
              </div>
              <div className="backline_item">
                <div className="backline_animate"></div>
              </div>
              <div className="backline_item">
                <div className="backline_animate"></div>
              </div>
            </div>

            <Social classOther="home_social home_social_pc" />

            <div className="container home_container">
              <div className="home_row row">
                <div className="home_col home_col-content col-xl-9 col-lg-11 col-md-10 col-sm-11">
                  <div className="home_content">
                    <h1 className="title-style home_maintitle">
                      <Trans i18nKey="homeTitle"></Trans>
                    </h1>
                    <p className="desc-style home_description">
                      <Trans i18nKey="homeDesc"></Trans>
                    </p>
                    <div className="home_links">
                      <Link
                        to="/portfolio"
                        className="m-button hover-link home_link home_link_rout"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseMove}>
                        <span>{t('btnPortfolio')}</span>
                      </Link>
                      <Link
                        to="/callback"
                        className="m-button hover-link home_link home_link_callback"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseMove}>
                        <span>{t('btnSendUs')}</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="home_col home_col-pic col-xl-3  col-lg-1 col-md-2 col-sm-1">
                  {isLargScreen && (
                    <Player autoplay loop src="animation.json" className="home_lottie"></Player>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Home;
