import React from "react";
import { useTranslation, Trans } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const getYears = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <footer className="footer">
      <div className="container-full">
        <div className="footer_wrap">
          <p className="footer_slog">
            <Trans i18nKey="slog" />
          </p>
          <p className="footer_copy">
            {t("copy")} {getYears()}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
