import React, { useEffect, useRef } from 'react';
import { decode } from 'html-entities';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getPostId, clearPostId } from '../../features/blog/postsSlice';

import ArticleLoader from '../../Components/Skeleton/ArticleLoader';
import CopyToClip from '../../Components/CopyToClip/CopyToClip';

const Article = () => {
  const { postId, loading } = useSelector((state) => state.posts);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const componentWillUnmount = useRef(false);

  useEffect(() => {
    dispatch(getPostId(params.id));
    return () => {
      componentWillUnmount.current = true;
    };
  }, [dispatch, params]);

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) {
        dispatch(clearPostId());
      }
    };
  }, [dispatch]);

  const { title, content, featured_media_src_url } = postId;

  return (
    <>
      <Helmet>
        <title>StarCoding 🚀 - {`${t('navBlog')} ${params.id}`}</title>
      </Helmet>

      <motion.div className="page-wrapper" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="blog">
          <div className="blog_container container">
            {loading ? (
              <ArticleLoader />
            ) : (
              <div className="article">
                <div className="article_preview">
                  <img className="article_preview_img" src={`${featured_media_src_url}`} alt={title?.rendered} />
                </div>

                <div className="article_moreinfo">
                  <button className="article_goback" onClick={() => navigate(-1)}>
                    {t('pageback')}
                  </button>
                  <CopyToClip classOther="article_copytoclip" copyText={window.location.href} />
                </div>

                <div className="article_maintitle">
                  <h1 className="article_maintitle_title">{decode(title?.rendered)}</h1>
                </div>

                <div className="article_content" dangerouslySetInnerHTML={{ __html: decode(content?.rendered) }}></div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Article;
